import { APITypesV2 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["questionnaire", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return await apiClient.questionnaire.getQuestionnaireResponses(
    { patientId },
    {}
  ).result;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

function useQuestionnaireQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery(queryKey(patientId), queryFn(apiClient, patientId), {
    staleTime: Infinity,
    ...options,
  });
}

export function useIsQuestionnaireCompleteQuery(
  options: Omit<QueryOptions<Body, Key, boolean>, "select"> = {}
) {
  return useQuestionnaireQuery({
    ...options,
    select: (data) => {
      const latestQuestionnaireResponse = data.items.find((response) =>
        // filter out the immediate-risk-assessment responses
        response.questionnaireType.startsWith("onboarding/")
      );

      if (latestQuestionnaireResponse == null) {
        return false;
      }

      return (
        latestQuestionnaireResponse.state ===
        APITypesV2.QuestionnaireResponseState.Complete
      );
    },
  });
}

useQuestionnaireQuery.queryKey = queryKey;
useQuestionnaireQuery.queryFn = queryFn;
